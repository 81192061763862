// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.
import "mdn-polyfills/Node.prototype.firstElementChild";

import parseXML from "xml-parse-from-string";

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faFacebook            as fabFacebook              } from "@fortawesome/free-brands-svg-icons/faFacebook";

import { faAngleDown           as farFaAngleDown           } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faClock               as farFaClock               } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faComment             as farFaComment             } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faFolder              as farFaFolder              } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faExclamationTriangle as farFaExclamationTriangle } from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import { faQuestionCircle      as farFaQuestionCircle      } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faSearch              as farFaSearch              } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTag                 as farFaTag                 } from "@fortawesome/pro-regular-svg-icons/faTag";

import { faBars                as fasFaBars                } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretLeft           as fasFaCaretLeft           } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight          as fasFaCaretRight          } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faEnvelope            as fasFaEnvelope            } from "@fortawesome/pro-solid-svg-icons/faEnvelope";

import svgCamera    from "../../media/fac-camera.svg";
import svgClipboard from "../../media/fac-clipboard.svg";
import svgRun       from "../../media/fac-run.svg";
import svgTrophy    from "../../media/fac-trophy.svg";

/**
 * Add brand icons
 */
library.add(fabFacebook);

/**
 * Add regular icons
 */
library.add(farFaAngleDown, farFaClock, farFaComment, farFaFolder, farFaExclamationTriangle, farFaQuestionCircle, farFaSearch, farFaTag);

/**
 * Add solid icons
 */
library.add(fasFaBars, fasFaCaretLeft, fasFaCaretRight, fasFaEnvelope);

/**
 * Add custom icons
 */
library.add({
    "camera": {
        iconName: "camera",
        prefix: "fac",
        icon: [
            svgCamera.attributes.viewBox.split(" ")[2],
            svgCamera.attributes.viewBox.split(" ")[3],
            [],
            "e000",
            parseXML(svgCamera.content).firstElementChild.getAttribute("d"),
        ]
    },
    "clipboard": {
        iconName: "clipboard",
        prefix: "fac",
        icon: [
            svgClipboard.attributes.viewBox.split(" ")[2],
            svgClipboard.attributes.viewBox.split(" ")[3],
            [],
            "e001",
            parseXML(svgClipboard.content).firstElementChild.getAttribute("d"),
        ]
    },
    "run": {
        iconName: "run",
        prefix: "fac",
        icon: [
            svgRun.attributes.viewBox.split(" ")[2],
            svgRun.attributes.viewBox.split(" ")[3],
            [],
            "e002",
            parseXML(svgRun.content).firstElementChild.getAttribute("d"),
        ]
    },
    "trophy": {
        iconName: "trophy",
        prefix: "fac",
        icon: [
            svgTrophy.attributes.viewBox.split(" ")[2],
            svgTrophy.attributes.viewBox.split(" ")[3],
            [],
            "e003",
            parseXML(svgTrophy.content).firstElementChild.getAttribute("d"),
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
